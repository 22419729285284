"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumberService = void 0;
const tslib_1 = require("tslib");
const base_service_1 = require("./base.service");
class PhoneNumberService extends base_service_1.RestService {
    constructor() {
        super();
    }
    getAllPhoneNumbers(baseRequest) {
        const _super = Object.create(null, {
            get: { get: () => super.get }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(baseRequest.token);
            const url = this.getCloudAPIURL(baseRequest.whatsAppBusinessAccountID, 'phone_numbers');
            return yield _super.get.call(this, url, config);
        });
    }
    getPhoneNumberIDInfo(baseRequest) {
        const _super = Object.create(null, {
            get: { get: () => super.get }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(baseRequest.token);
            const url = this.getCloudAPIURL(baseRequest.phoneNumberID, '');
            return yield _super.get.call(this, url, config);
        });
    }
    getWhatsAppBusinessAccountInfo(baseRequest) {
        const _super = Object.create(null, {
            get: { get: () => super.get }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(baseRequest.token);
            const url = this.getCloudAPIURL(baseRequest.whatsAppBusinessAccountID, '');
            return yield _super.get.call(this, url, config);
        });
    }
    registerPhoneNumber(baseRequest) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(baseRequest.token);
            const url = this.getCloudAPIURL(baseRequest.phoneNumberID, 'register');
            const json = {
                messaging_product: 'whatsapp',
                pin: '123456'
            };
            return yield _super.post.call(this, url, json, config);
        });
    }
    subscribeWABA(baseRequest) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(baseRequest.token);
            const url = this.getCloudAPIURL(baseRequest.whatsAppBusinessAccountID, 'subscribed_apps');
            return yield _super.post.call(this, url, null, config);
        });
    }
}
exports.PhoneNumberService = PhoneNumberService;
